<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div class="select-title">
          <span>供方管理</span>
        </div>
        <div class="select-button">
          <div class="select-items">
                        <span @click="handleInsert">
                            <img src="@/assets/icon/scanner.png"/>添加供方
                        </span>
          </div>
          <div class="select-items">
                        <span @click="downloadSellerTemplate">
                            <img src="@/assets/icon/download.png"/>下载模板
                        </span>
          </div>
          <div class="select-items">
                        <span @click="ImportDialogVisible = true">
                            <img src="@/assets/icon/maintain.png"/>批量维护
                        </span>
          </div>
        </div>
      </div>
      <div class="select-content">
        <el-form ref="supplierForm" :model="selectForm" label-width="90px">
          <el-row class="row-first">
            <el-col :span="7">
              <el-form-item label="供方编码">
                <el-input placeholder="请输入供方编码" v-model.trim="selectForm.uniqueCode"
                          maxlength="20" @keyup.enter.native="submitForm()"/>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="供方名称">
                <el-input placeholder="请输入供方名称" v-model.trim="selectForm.fullName"
                          maxlength="128" @keyup.enter.native="submitForm()"/>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="供方简称">
                <el-input placeholder="请输入供方简称" v-model.trim="selectForm.shortName"
                          maxlength="128" @keyup.enter.native="submitForm()"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="平台用户">
                <el-select v-model="selectForm.innerSystem">
                  <el-option v-for="item in innerSystems"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="协同状态">
                <el-select v-model="selectForm.bindingStatus">
                  <el-option v-for="item in bindingStatuses"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="抬头信息">
                <el-select v-model="selectForm.riseMessage">
                  <el-option v-for="item in riseMessage"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-button type="primary" class="btn" @click="submitForm">查询</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div class="content-table">
        <el-table
          :data="tableData"
          stripe
          border
          ref="supplierTable"
          v-loading="tableLoading"
          :header-cell-style="handleHeaderCellStyle"
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="50" fixed="left"/>
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column prop="uniqueCode" label="供方编码" width="160"></el-table-column>
          <el-table-column prop="shortName" label="供方简称" width="140"></el-table-column>
          <el-table-column prop="fullName" label="供方名称" width="200"></el-table-column>
          <el-table-column prop="taxNo" label="供方税号" width="200"></el-table-column>
          <el-table-column prop="taxLevel" label="纳税级别" width="120">
            <template slot-scope="scope">
              <div>
                {{handleValueToLabel('CompanyTaxLevel',scope.row.taxLevel)}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="purType" label="供方分类" width="120">
            <template slot-scope="scope">
              <div>
                {{handleValueToLabel('CooperateSellerPurType',scope.row.purType)}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="supplierOrgId" label="平台用户" width="100"
                           :formatter="fmtInnerSystem"></el-table-column>
          <el-table-column prop="" label="抬头信息" width="100"
                           :formatter="fmtRiseStatus"></el-table-column>
          <el-table-column prop="bindingStatus" label="协同状态" width="100"
                           :formatter="fmtBindingStatus"></el-table-column>
          <el-table-column prop="operate" label="操作" min-width="120" fixed="right">
            <template slot-scope="scope">
              <div class="operate-button">
                <el-button type="text" size="medium" style="padding: 0"
                           @click="handleDrawer(scope.row)">详情
                </el-button>
                <el-button type="text" :disabled="handleDisabledCooperate(scope.row)"
                           size="medium" style="padding: 0"
                           @click="handleCooperate(scope.row)">发起协同
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page.sync="page.current"
                       :page-sizes="pageSizes"
                       :page-size="page.size"
                       layout="total, sizes, prev, pager, next"
                       :total="page.total">
        </el-pagination>
      </div>
    </div>
    <el-drawer append-to-body
      :visible.sync="drawer"
      :with-header="false"
      :before-close="handleClose"
      size="50%">
      <seller-detail v-if="drawer" :sellerDetailData="sellerDetailDataDetail"></seller-detail>
    </el-drawer>
    <supplier-insert ref="supplierInsert" :insert-visible.sync="insertVisible" @refresh="submitForm"></supplier-insert>
    <el-dialog append-to-body :visible.sync="cooperateVisible" width="360px" :show-close="false">
      <div class="dialog-wrap">
        <div v-if="supplierSelections.length < 1">
          <div class="dialog-title">
            <span>发起协同</span>
          </div>
          <div class="dialog-main">
            是否与"<span>{{supplier.name}}</span>"建立结算单协同?
          </div>
        </div>
        <div v-if="supplierSelections.length > 0">
          <div class="dialog-title">
            <span>批量协同</span>
            <span class="tip">已勾选供方信息<span>{{supplierSelections.length}}</span>条</span>
          </div>
          <div class="dialog-main">
            <div class="label">
              <span>可协同：</span>
              <span class="success">{{suppliers.length}}条</span>
            </div>
            <div class="label">
              <span>不可协同：</span>
              <span class="error">{{supplierSelections.length - suppliers.length}}条</span>
              <el-tooltip effect="dark" placement="right">
                <div slot="content">
                  <div>不可协同：协同状态为“已协同”、“已发送”，“该供方非平台用户”时，不可发送协同请求！</div>
                  <div>可协同：“待协同”或“ 已拒绝”的协同状态！</div>
                </div>
                <i class="el-icon-warning dialog-tip"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="dialog-footer" slot="footer">
          <el-button @click="cooperateVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSaveCooperate" :disabled="btnDisabled">
            发起协同
          </el-button>
        </div>
      </div>
    </el-dialog>
    <batch-deleted
      :class="deleteSelectionsClass"
      v-if="supplierSelections.length > 0"
      type="cooperate"
      selection-message="条信息"
      primary-message="发起批量协同"
      :deleteSelections="supplierSelections"
      @handleCooperate="handleCooperate"
      @cancelDeleteBatch="cancelDeleteBatch"
    >
    </batch-deleted>
    <el-dialog append-to-body title="批量维护" :visible.sync="ImportDialogVisible"
               :show-close="false"
               v-loading="importLoading"
               width="496px"
               :close-on-press-escape=false
    >
      <div class="upload-dialog-wrap">
        <div class="dialog-button">
          <el-input v-model="importFile.importExcelFileName"></el-input>
          <el-upload
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            action="#" :multiple="false" :show-file-list="false"
            :before-upload="beforeUplaod"
            :on-change="uploadFile">
            <el-button type="default" icon="el-icon-upload2">
              导入模板文件
            </el-button>
          </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
                    <el-button @click="handleImportDialogCancel">取 消</el-button>
                    <el-button type="primary" @click="batchImportCooperateSeller"
                               :disabled="disableImportButton">导 入</el-button>
                </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tableStyle, pagingMethods, changeMethods } from '@/util/mixins'
import {
  getList,
  saveBatchBinding,
  batchImportCooperateSeller,
  downloadCooperateSellerTemplate
} from '@/service/sellerManage'
import SellerDetail from '@/views/cooperate/seller-manage/components/SellerDetail'
import SupplierInsert from './components/SupplierInsert'
import BatchDeleted from '@/components/BatchDeleted'
import { downloadFile } from '../../../util/duola'

export default {
  name: 'CooSeller',
  mixins: [tableStyle, pagingMethods, changeMethods],
  components: { SellerDetail, SupplierInsert, BatchDeleted },
  data () {
    return {
      selectForm: {
        uniqueCode: '',
        name: '',
        shortName: '',
        bindingStatus: '',
        innerSystem: '',
        riseMessage: ''
      },
      btnDisabled: false,
      riseMessage: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '未完善'
        },
        {
          value: 1,
          label: '已完善'
        }
      ],
      supplier: {},
      innerSystems: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        }
      ],
      bindingStatuses: [{ value: '', label: '全部' }, ...this.$store.state.enums.CooperateBindingStatus],
      supplierSelections: [], // 勾选的供方列表
      suppliers: [], // 发起协同的供方列表
      tableLoading: false,
      drawer: false,
      sellerDetailDataDetail: {
        taxNo: ''
      },
      insertVisible: false,
      cooperateVisible: false,
      deleteSelectionsClass: 'batch-deleted',
      tableData: [],
      importFile: {
        importExcelFileName: '',
        importExcelFile: {}
      },
      ImportDialogVisible: false,
      importLoading: false,
    }
  },
  watch: {
    '$store.state.isCollapse': {
      handler () {
        this.deleteSelectionsClass = this.$store.state.isCollapse ? 'batch-deleted-hidden' : 'batch-deleted'
      },
      immediate: true
    }
  },
  created () {
    this.submitForm()
  },
  computed: {
    disableImportButton () {
      return this.importFile.importExcelFileName == '' || this.importFile.importExcelFileName == null
    }
  },
  methods: {
    /* 构建参数，提交表单 */
    submitForm () {
      const param = {
        filter: this.selectForm,
        paging: this.page
      }
      this.refreshTable(param)
    },
    /* 新增供方 */
    handleInsert () {
      this.insertVisible = true
      this.$refs.supplierInsert.reset()
    },
    /* 选中数据 */
    handleSelectionChange (suppliers) {
      this.supplierSelections = suppliers
    },
    /* 取消多选 */
    cancelDeleteBatch () {
      this.supplierSelections = []
      this.$refs.supplierTable.clearSelection()
    },
    /* 发起协同 */
    handleCooperate (supplier) {
      this.cooperateVisible = true
      // 数据处理
      this.suppliers = []
      if (supplier && supplier.id) {
        this.supplierSelections = []
        this.supplier = supplier
        this.suppliers.push(this.supplier)
      } else {
        this.suppliers = this.supplierSelections.filter(s => s.bindingStatus != 'ALREADYCOOPERATE' && s.bindingStatus != 'SENTCOOPERATE' && s.supplierOrgId > 0)
      }
    },
    /* 发起协同 */
    async handleSaveCooperate () {
      this.btnDisabled = true
      const { success } = await saveBatchBinding({ suppliers: this.suppliers })
      this.btnDisabled = false
      if (success) {
        this.toast('协同请求发送成功', 'success', () => {
          this.cooperateVisible = false
          this.submitForm()
        })
      }
    },
    /* 渲染列表 */
    async refreshTable (param) {
      this.tableLoading = true
      const res = await getList(param)
      this.tableLoading = false
      if (res.success) {
        this.tableData = res.data.records
        this.page.total = res.data.total
      }
    },
    // 操作抽屉
    handleDrawer (row) {
      this.drawer = true
      this.sellerDetailDataDetail = row
    },
    // 格式化是否平台用户
    fmtInnerSystem (row) {
      return row.supplierOrgId && row.supplierOrgId > 0 ? '是' : '否'
    },
    // 抬头信息显示
    fmtRiseStatus (row) {
      return row.taxNo ? '已完善' : '未完善'
    },
    // 格式化协同状态
    fmtBindingStatus (row) {
      return this.handleValueToLabel('CooperateBindingStatus', row.bindingStatus)
    },
    // 发起按钮是否可点 ： 是否平台用户， 是否待发起和已拒绝状态
    handleDisabledCooperate (row) {
      const disabled = row.supplierOrgId && row.supplierOrgId > 0 && row.bindingStatus != 'ALREADYCOOPERATE' && row.bindingStatus != 'SENTCOOPERATE'
      return !disabled
    },
    // 下载批量维护模板
    async downloadSellerTemplate () {
      let suppers = this.supplierSelections
      let sellerIds = suppers.map(item => item.id)
      const rsp = await downloadCooperateSellerTemplate(sellerIds)
      let error = downloadFile(rsp)
      if (error != '' && error != undefined) {
        this.toast(error, 'warning')
      }
    },
    uploadFile (file) {
      this.importFile.importExcelFileName = file.name
      this.importFile.importExcelFile = file.raw
    },
    // 批量维护模板上传
    async batchImportCooperateSeller () {
      let formData = new FormData()
      formData.append('file', this.importFile.importExcelFile)
      this.importLoading = true
      const res = await batchImportCooperateSeller(formData)
      this.importLoading = false
      if (res.success) {
        this.toast('批量维护供方信息成功', 'success', () => this.ImportDialogVisible = false)
      }
      this.resetImportFile()
      this.ImportDialogVisible = false
      this.submitForm()
    },
    handleImportDialogCancel () {
      this.resetImportFile()
      this.ImportDialogVisible = false
    },
    beforeUplaod () {
      let file = this.importFile.importExcelFile
      const isExcel = file.type === 'application/vnd.ms-excel'
      const isXlsxExcel = file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

      if (isExcel || isXlsxExcel) {
        return true
      } else {
        this.toast('只能上传excel文件')
        this.resetImportFile()
        return false
      }
    },
    resetImportFile () {
      this.importFile.importExcelFileName = ''
      this.importFile.importExcelFile = ''
    },
    //关闭从新刷新
    handleClose (done) {
      this.submitForm()
      done()
    }
  }
}
</script>

<style lang="scss" scoped>

  .select-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px 24px;

    .select-button {
      div:first-child {
        margin-right: 24px;
        width: 130px;
        border-right: #CCCCCC 1px solid;
      }
    }

    .select-items {
      display: inline-flex;

      span {
        color: #3d94ff;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        line-height: 22px;

        img {
          width: 20px;
          height: 20px;
          vertical-align: sub;
          margin-right: 4px;
        }
      }

      span:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .select-title span:before {
      content: "|";
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  ::v-deep .el-form-item__label {
    text-align: left;
  }

  .content-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% - 120px);

    .content-table {
      .el-button {
        padding: 0;
      }
    }
  }

  .dialog-wrap {
    .dialog-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 24px;
      font-size: 18px;
      color: #333333;
      margin-top: -40px;

      .tip {
        color: #999999;
        font-size: 14px;
      }
    }

    .dialog-main {
      padding: 24px;
      text-align: left;

      .label {
        color: #666666;
        font-size: 14px;
      }

      .success {
        color: #09bb07;
      }

      .error {
        color: #F5222D;
      }

      .dialog-tip {
        color: #F5222D;
        margin-left: 5px;
        font-size: 14px
      }
    }

    .dialog-footer {
      height: 32px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 0px 24px 24px 24px;
    }
  }

  .upload-dialog-wrap {
    display: flex;
    flex-direction: column;
    height: 120px;

    .dialog-button {
      display: flex;
      justify-content: space-between;
      padding-left: 24px;
      margin-right: 24px;

      .el-input {
        width: 280px
      }
    }

    .dialog-footer {
      height: 32px;
      align-self: flex-end;
      margin-top: 30px;
      padding: 0px 24px 24px 24px;
    }
  }

  ::v-deep .el-drawer__body {
    overflow-y: auto;
    margin: 24px 0;
  }
</style>
