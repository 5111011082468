<template>
    <div class="content-wrap">
        <div class="select-title">
            供应商详情
        </div>
        <div class="content-head">
            <div>
                <div><span>供应商编码：</span>
                    <span>{{ sellerDetailDataCopy.uniqueCode }}</span></div>
                <div>
                    <span>供应商状态：</span>
                    <span>{{handleValueToLabel('CooperateSellerStatus', sellerDetailDataCopy.status)}}</span>
                </div>
            </div>
            <div>
                <div>
                    <span>供应商全称：</span>
                    <span>{{ sellerDetailDataCopy.fullName }}</span>
                </div>
                <div>
                    <span>供应商简称：</span>
                    <span>{{ sellerDetailDataCopy.shortName }}</span>
                </div>
            </div>
            <div>
                <div>
                    <span>供应商分类：</span>
                    <span>{{handleValueToLabel('CooperateSellerPurType', sellerDetailDataCopy.purType)}}</span>
                </div>
                <div>
                    <span>纳税级别：</span>
                    <span>{{handleValueToLabel('CompanyTaxLevel', sellerDetailDataCopy.taxLevel)}}</span>
                </div>
            </div>
            <div>
                <div>
                    <span>是否内部供应商：</span>
                    <span>{{ fmtIsInner(sellerDetailDataCopy.isInner) }}</span>
                </div>
                <div>
                    <span>入住时间：</span>
                    <span>{{ sellerDetailDataCopy.enterTime }}</span>
                </div>
            </div>
            <div>
                <div>
                    <span>地址：</span>
                    <span>{{ sellerDetailDataCopy.address }}</span>
                </div>
            </div>
        </div>
        <div class="content-middle">
            <div class="content-title">备注</div>
            <div>
                <el-input type="textarea" :rows="4" :disabled="true" v-model="sellerDetailDataCopy.remark">
                </el-input>
            </div>
        </div>
        <div class="link-foot"></div>
        <div class="content-foot">
            <div class="content-title">
                开票信息
            </div>
            <div>
                <div>
                    <span>名称：</span>
                    <el-input placeholder="请输入内容" :disabled="!editMode" v-model.trim="sellerDetailDataCopy.name"
                              maxlength="200"
                    ></el-input>
                </div>
                <div>
                    <span>纳税人识别号：</span>
                    <el-input placeholder="请输入内容" :disabled="!editMode" v-model.trim="sellerDetailDataCopy.taxNo"
                              maxlength="100"></el-input>
                </div>
                <div>
                    <span>地址、电话：</span>
                    <el-input placeholder="请输入内容" :disabled="!editMode" v-model.trim="sellerDetailDataCopy.addressTel"
                              maxlength="100"></el-input>
                </div>
                <div>
                    <span>开户行及帐号：</span>
                    <el-input placeholder="请输入内容" :disabled="!editMode" v-model.trim="sellerDetailDataCopy.bankAccount"
                              maxlength="100"></el-input>
                </div>
                <div>
                    <span>收件邮箱：</span>
                    <el-input placeholder="请输入内容" :disabled="!editMode" v-model.trim="sellerDetailDataCopy.email"
                              maxlength="100"></el-input>
                </div>
            </div>
            <div>
                <el-button class="btn" v-show="editMode" @click="handleCancel()">取消
                </el-button>
                <el-button :disabled="sellerDetailData.bindingStatus == 'ALREADYCOOPERATE'" type="primary" class="btn"
                           @click="handleSave()">{{ saveBtnName }}
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import regexLib from "@/assets/js/regex-lib";
    import {changeMethods} from "@/util/mixins";
    import {saveSellerDetail} from "@/service/sellerManage";

    export default {
        name: "SellerDetail",
        props: ["sellerDetailData", "id"],
        mixins: [changeMethods],

        data() {
            return {
                isInnerProps: "",
                editMode: false,
                sellerDetailDataCopy: JSON.parse(JSON.stringify(this.sellerDetailData))
            }
        },
        computed: {
            saveBtnName() {
                return this.editMode ? '保存' : '编辑';
            }
        },
        created() {
            console.log("22", this.sellerDetailData)
        },
        methods: {
            /**处理是否是内部供应商**/
            fmtIsInner(value) {
                return value ? "是" : "否";
            },
            /*保存*/
            handleSave() {
                if (!this.editMode) {
                    this.editMode = true;
                } else {
                    this.saveSeller();
                    this.editMode = false;
                }
            },
            handleCancel() {
                this.sellerDetailDataCopy = JSON.parse(JSON.stringify(this.sellerDetailData));
                this.editMode = false;
            },
            async saveSeller() {
                const res = await saveSellerDetail(this.sellerDetailDataCopy);
                if (res.success) {
                    this.toast("保存开票信息成功！", "success", () => {
                        this.editMode = false;
                    })
                } else {
                    this.toast("保存开票信息失败！", "error")
                }
            },
            // 禁止输入特殊字符
            handleNoInputSpecialCharacter(e) {
                let flag = regexLib.SupplierName;
                e.target.value = e.target.value.replace(flag, "");
            },
        },

    }
</script>

<style scoped lang="scss">
    .link-foot {
        margin-top: 12px;
        margin-bottom: 24px;
        width: 100%;
        height: 1px;
        border-top: solid #CCCCCC 1px;
    }

    .el-input, .el-input__inner {
        width: 100%
    }

    .select-title {
        margin-bottom: 24px;
    }

    .content-wrap {
        padding: 24px
    }

    .content-title {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
    }

    .content-middle {
        div {
            margin-bottom: 12px;
        }
    }

    .content-foot {
        div {
            margin-bottom: 12px;
        }

        div:nth-child(2) {
            display: flex;
            flex-direction: column;

            div {
                display: flex;
                flex-direction: row;

                span {
                    width: 120px;
                    line-height: 30px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }

        div:nth-child(3) {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    .content-head {
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;

            div {
                display: flex;
                width: 250px;
                text-align: left;
                flex-direction: row;
                justify-content: flex-start;

                span:first-child {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                }

                span:nth-child(2) {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                }
            }
        }
    }

</style>