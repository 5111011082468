<template>
    <div class="content-wrap">
        <span>已选择<i class="deletes-length">{{ deleteSelections.length }}</i>{{selectionMessage}} </span>
        <div class="deletes-btns">
            <el-button type="default" @click="handleCancel">取消选择</el-button>
            <el-button type="primary" @click="handleIdentify">{{ primaryMessage }}</el-button>
        </div>
    </div>
</template>

<script>


    export default {
        name: "BatchDeleted",
        props: {
            deleteSelections: {
                type: Array
            },
            message: {
                type: String,
                default: ""
            },
            selectionMessage: {
                type: String,
                default: "张发票"
            },
            primaryMessage: {
                type: String,
                default: "批量删除"
            },
            type: {
                type: String,
                default: "deleted"
            }
        },
      created () {
        const f = new Fpp();
      },
      methods: {
            handleIdentify() {
                switch (this.type) {
                    case "cooperate":
                        this.handleCooperate();
                        break;
                    default:
                        this.handleDelete();
                        return;
                }
            },
            handleCooperate() {
                this.$emit("handleCooperate")
            },
            /* 批量删除 */
            handleDelete() {
                this.$confirm(this.message, '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$emit("deleteBatch")
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            /* 取消选择 */
            handleCancel() {
                this.$emit("cancelDeleteBatch")
            }
        }
    }
</script>

<style lang="scss" scoped>
    .content-wrap {
        background-color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        span {
            margin-left: 24px;
            color: #333333;
        }

        .deletes-length {
            display: inline-block;
            padding: 0 10px;
            color: #1890FF;
        }

        .deletes-btns {
            margin-right: 24px;
            display: inline-block;
        }
    }
</style>
